import { Route } from "@/routes/routes.types";

/**
 * Create routes object where each route has its path and params
 */
export const routes = {
    dashboard: {
        path: "/dashboard",
    },

    horizonVersion: {
        path: "/software/horizon-version",
    },

    numberOfRobots: {
        path: "/hardware/number-of-robots",
    },

    customerDetail: {
        path: "/customer-detail/:customerNumber", // customerId is just current assumption
    },
} as const satisfies Record<string, Route>;

/**
 * Define Routes type representing all the defined routes
 */
export type Routes = typeof routes;
export type RoutesPaths = Pick<(typeof routes)[keyof typeof routes], "path">["path"];
