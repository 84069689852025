import { z } from "zod";
export const farmOverviewDtoSchema = z.object({
    farmLicenseKey: z.string(),
    farmHorizonVersion: z.string(),
    customerName: z.string(),
    customerNumber: z.string(),
    customerCountry: z.string().nullable(),
    lelyCenterName: z.string().nullable(),
    lelyCenterNumber: z.string(),
    clusterName: z.string(),
});
